export default {
  learningContent: {
    buttonTitle: "Pédagogique",
    title: "Learning",
    description:
      "Here you can find learning materials that can help farmers develop their skills and increase their farming knowledge.",
    button: "Go To Learning",
    courses: "Courses",
    content: "Content",
    downloads: "Downloads",
    list: "List",
    filter_list_text: "Filter List By",
    lessons: "Lesson list",
    lessonView: "Lessons",
    language: "LANGUAGE",
    course: "Course",
    select_language: "Select Language",
    search_course: "Search course...",
    download_msg: "Your downloaded lessons will appear here",
    selected_filters: "Selected Filters",
    views: "Views",
    download: "Download",
    downloading: "Downloading...",
    delete: "Delete",
    deleting: "Deleting...",
    NoSpaceAvailable: "Not enough storage space",
    lesson: "Lesson",
  },
  countries: {
    Niger: "Niger",
    Tanzania: "Tanzanie",
    Rwanda: "Rwanda",
    Zambia: "Zambia",
    Honduras: "Honduras",
    Nigeria: "Nigeria",
    Lebanon: "Liban",
    Mozambique: "Moçambique",
    Moçambique: "Moçambique",
    Ghana: "Ghana",
    Syria: "Syria",
    Palestine: "Palestine",
  },
  title_copy: {
    status: "status",
    version_msg: "You're using the test version of vsla.online",
    cycles_title: "Saving cycles",
    header_login: "S`IDENTIFIER",
    header_signup: "S'INSCRIRE",
    create_group: "CRÉER UN GROUPE",
    group_finance: "Cotisation sociale:",
    group_header: "GROUPE",
    loading: "En chargement ...",
    updating: "Mise à jour ...",
    check_status: "checking group status",
    no_vsla: "Aucun VSLA trouvé",
    create_meeting: "CRÉER UNE RÉUNION",
    meeting_members: "membre",
    meeting: "RÉUNION",
    prev_meeting: "RÉUNIONS PRÉCÉDENTES",
    add_member: "INFORMATIONS PERSONNELLES",
    buy_shares: "ACHETER DES ACTIONS",
    sell_shares: "VENDRE LES PARTS",
    fine: "PAYER L'AMENDE",
    welfare: "COTISATIONS SOCIALES",
    welfare_request: "DEMANDE DE BIEN-ÊTRE",
    loan_writeoff: "SUPPRIMER LE PRÊT",
    membership_fee: "FRAIS D'ADHÉSION",
    loan: "PRÊT",
    funds: "CAISSE DU GROUPE",
    more_details: "Plus de détails",
    vsla_actions: "MMD actions",
    mem_actions: "Actions Membre",
    memberlist_overview: "LISTE",
    mem_menu: "AJOUTER DES MEMBRES",
    loan_repayment: "REMBOURSEMENT DE PRÊT",
    outstanding_loan: "Prêt impayé:",
    attendees: "Participants à la réunion",
    share_value: "Partager la valeur",
    settings: "RÉGLAGES",
    current_savings: "Économies",
    cycle_duration: "Durée du cycle du groupe",
    addFundAccount: "AJOUTER UN COMPTE DE FONDS",
    supported_accounts: "Comptes pris en charge",
    lastTransaction: "Dernière transaction",
    otherIncome: "AUTRE REVENU",
    otherExpenses: "AUTRES DÉPENSES",
    cashOut: "Retirer",
    warning: "Warning",
    end_of_cycle_cash_out: "End-cycle cash out",
    offline_meeting: "Offline meeting",
    reminder: "Reminder",
  },

  menu: {
    learning_content: "tutorials",
    vlsa: "Groupe MMD",
    group: "Groupe",
    meeting: "Réunions",
    create_vsla: "Créer un groupe MMD",
    vsla_overview: "Vue d`ensemble du groupe",
    new_meeting: "Commencer une réunion",
    prev_meeting: "Réunions précédentes",
    offline_meeting: "Offline meeting",
    vsla_saving: "Cotisations:",
    vlsa_loan: "Total des prêts:",
    vlsa_balance: "Solde actuel:",
    funds: "Caisse du groupe",
    members: "Membres",
    members_overview: "Vue d`ensemble des membres",
    add_member: "Ajouter un Membre",
    end_meeting: "Fin de la réunion",
    current_meeting: "Réunion en cours",
    settings: "Réglages",
    options: "Les options",
    language: "Langage",
    close: "Fermer",
    savingsFund: "Fonds d'épargne",
    welfareFund: "Fonds de bien-être",
    loanInterestFund: "Fonds d'intérêt pour les prêts",
    fineFund: "Fonds d'amende",
    membershipFund: "Fonds d'adhésion",
    end_of_cycle_cash_out: "End of cycle cash out",
  },

  menu_mem: {
    overview: "Voir le compte du membre",
    welfare: "Cotisations Sociales",
    welfare_request: "Demande de bien-être",
    buy_shares: "Faire un versement",
    create_loan: "Obtenir un prêt",
    repay_loan: "Rembourser un prêt",
    writeoff_loan: "Supprimer le prêt",
    pay_fine: "Payer l'amende",
    membership_fee: "Frais d'adhésion",
    sell_shares: "Vendre les parts",
    transaction_amount: "Montant de la transaction",
    other_income: "Autre revenu",
    other_expenses: "Autres dépenses",
    cashout: "Retirer",
    group_income: "Group Income",
    group_expense: "Group Expenses",
    fine_payment: "Fine Payment",
    welfare_contribution: "Welfare Contribution",
    loan_repayment: "LOAN REPAYMENT",
    loan_interest_repayment: "Loan Interest Repayment",
    fine_payment_reversal: "Fine Payment Reversed",
    buy_shares_reversal: "Buy Shares Reversed",
    welfare_contribution_reversal: "Welfare Contribution Reversed",
    sell_shares_reversal: "Sell Shares Reversed",
    loan_interest_repayment_reversal: "Loan Interest Repayment Reversed",
    welfare_request_reversal: "Welfare Request Reversed",
    membership_fee_reversal: "Membership Fee Reversed",
    savings_cash_out: "Savings cashout",
    midCycle_Cashout: "Mid-cycle cash out",
    group_income_fund: "Revenu de groupe - Épargne",
    group_income_fine: "Revenu de groupe - Amende",
    group_income_welfare: "Revenu de groupe - Bien-être",
    group_expense_fund: "Dépense de groupe - Épargne",
    group_expense_fine: "Dépense de groupe - Amende",
    group_expense_welfare: "Dépense de groupe - Bien-être",
  },

  mem_overview: {
    welfare: "Cotisations Sociales:",
    shares_in_francs: "Montant des cotisations:",
    loan: "Montant du prêt:",
    shares_in_numbers: "Nombre de versements:",
    writeoff_loan: "Supprimer le prêt",
    created_at: "Date de création",
    welfare_total: "Cotisations Sociales:",
    shares_total: "Parts des membres:",
  },

  header: {
    home: "Accueil",
    vsla: "MMD",
    logout: "Déconnexion",
    whats_new: "What's New",
    tutorials: "Tutorials",
    legal: "Légal",
  },

  placeholder_copy: {
    search_no_result_message: "Non trouvé",
    search_placeholder: "Rechercher un nom",
    loader_msg1: "Créer un profil de groupe",
    loader_msg2: "Créer des entrées de comptes",
    loader_msg3: "Attribution de comptes au groupe",
    loader_msg4: "Nommer un secrétaire d'application",
    add_account_loader_msg: "Création de comptes, dernière étape",
    group_loader_msg: "Créer un groupe, juste un instant",
    secretary_pay_interest: "Le secrétaire paie-t-il des intérêts pour les prêts",
    nationality: "Nationality",
    gender: "le genre",
    female: "femelle",
    male: "mâle",
    sms_code: "Vérifier le code",
    submit: "Soumettre",
    income_amount: "montante...",
    login_email: "Numéro de téléphone ...",
    login_password: "Mot de passe ...",
    login_submit: "S`identifier",
    login_submitting: "Vous connecter à ...",
    signUp_submit: "S'inscrire",
    signUp_submitting: "Vous inscrire..",
    create_group_name: "Nom du groupe ...",
    create_fund_submit: "Créer un fonds",
    create_group_location: "Emplacement du groupe ...",
    create_group_submit: "Créer un groupe",
    meeting_date: "sélectionner une date",
    meeting_submit: "Créer une réunion",
    meeting_submitting: "Creating Meeting",
    member_submit: "Créer un membre",
    member_name: "Nom complet ...",
    member_phone: "Numéro de Téléphone ....",
    number_of_shares: "Nombre de versements ...",
    shares_submit: "Faire un versement",
    share: "Versement",
    loan_amount: "Montant du prêt ...",
    loan_submit: "Obtenir un prêt",
    welfare_amount: "Montant ...",
    welfare_submit: "Payer la cotisation",
    payment_amount: "Montant du paiement ...",
    repay_submit: "Payer le prêt",
    loan_writeoff: "Supprimer le prêt",
    share_value_amount: "Part valeur montant..",
    share_value_submit: "Définir la valeur de partage",
    create_group_share_value: "Valeur de la part de cycle de groupe",
    role_in_group: "Sélectionnez le rôle dans le groupe",
    login_pin: "PIN",
    login_pin_confirm: "Confirmer le code PIN",
    create_group_federation: "Fédération ...",
    savings_cycle: "Cycle du groupe..",
    country: "Choisissez le pays",
    date_of_birth: "Sélectionnez la date de naissance",
    add_secretary_header: "Ajouter la secrétaire de groupe en premier",
    add_secretary_msg:
      "Pour un nouveau groupe, vous devez d'abord ajouter la secrétaire de groupe. Cliquez sur OK pour ajouter.",
    add_secretary_status: "Ajouter..",
    add_secretary: "Ajouter une secrétaire",
    total_amount: "Montant Total",
    fine_amount: "amende ...",
    fine_submit: "Amende",
    membership_amount: "Montant ...",
    membership_submit: "Payer les frais d'adhésion",
    sell_shares: "Vendre les parts",
    membership_reverse: "inverse",
    forgot_pin: "Vous avez oublié votre code PIN?",
    account_associated: "Entrez le numéro de téléphone associé à votre compte",
    token_sent: "Saisissez le code à 4 chiffres que vous avez reçu par SMS.",
    resend_token: "Renvoyez le code",
    submit_smscode: "Entrez votre nouveau code PIN à 4 chiffres",
    income_submit: "Retirer",
    select_nationality: "Select Nationalities",
    next: "Next",
    cashingOut: "Cashing out...",
    finish: "Finish",
    currency: "Select currency",
    select: "Select",
    savings_amount: "Savings amount",
    omt_recipt_number: "OMT Receipt number",
    enter_recipt_number: "Enter receipt number",
    savings_withdrawal: "Savings withdrawal",
    project_name: "Nom du projet",
    retry: "Retenter",
    remove: "Retirer",
    secretary_creation_msg: "La création du secrétaire est actuellement en cours...",
    secretary_creation_failed_msg:
      "La création du secrétaire a échoué. Veuillez réessayer",
  },

  delete: "Supprimer le groupe",
  delete_member: "Supprimer un membre",
  edit: "Modifier le groupe",
  save: "Valider",
  edit_member: "Modifier le membre",

  alert: {
    upload_error:
      "offline transaction upload encountered an error. Go to offline meeting record to see the error",
    upload_success:
      "Offline transactions have been uploaded and resolved. Check previous meetings records to see it",
    no_members: "Aucun membre dans ce groupe",
    no_savings_cash_out_personal:
      "Épargne encaissée bloquée, prêt personnel non apuré",
    no_savings_cash_out_group:
      "Épargne encaissée bloquée, prêt non apuré dans le groupe",
    no_membership_cash_out_personal:
      "Encaissement de l'adhésion verrouillé, prêt personnel non apuré",
    no_membership_cash_out_group:
      "Encaissement de l'adhésion bloqué, prêt non apuré dans le groupe",
    no_fine_cash_out_personal: "Amende bloquée, prêt personnel non apuré",
    no_fine_cash_out_group: "Fine cash out locked, Uncleared loan in group",
    no_welfare_cash_out_personal:
      "Amende encaissée bloquée, prêt non apuré dans le groupe",
    no_welfare_cash_out_group: "Welfare cash out locked, Uncleared loan in group",
    cash_out_success:
      "L'argent de l'aide sociale est bloqué, prêt non apuré dans le groupe",
    success: "Succès",
    cannot_end_meeting: "Can't end the meeting while a transaction in progress",
    failed: "Failed",
    repay_loans_message: "Repay the loans these members have",
    cannot_cash_out: "Can't cash out while a transaction in progress",
    all_members_cashed_out: "All members have been cashed out",
    no_internet: "No Internet",
    uploading_transactions: "Uploading transactions...",
    member_cashed_out: "Member has been cashed out",
    member_pending_loan: "Member has a loan they need to repay",
    cannot_delete_member_cashout_msg:
      "Impossible de supprimer un membre pendant que le retrait est en cours",
    cannot_delete_member_secretary_msg:
      "Impossible de supprimer le secretaire du groupe",
    cannot_delete_member_has_balance_msg:
      "Impossible de supprimer, le membre doit d'abord être encaissé",
  },

  dropdowns: {
    secretary: "Secrétaire",
    chairperson: "Présidente",
    box_keeper: "Trésorière",
    organiser: "organisateur",
    keyHolder: "Key Holder",
    money_counter: "Compteuse d'argent",
    member: "Membre",
    key_holder: "Key Holder",
    yes: "Oui",
    no: "Non",
    nine_months: "9 mois",
    six_months: "6 mois",
    year: "1 année",
    establishment_date: "Date d'établissement",
    business_development: "Business development",
    personal_emergency: "Personal emergency",
    other: "Other",
  },

  cards: {
    total_member: "Total membres",
    cycle_duration: "Durée du cycle",
    country: "Pays",
    locality: "Localité",
    federation: "Fédération",
    months: "mois",
    share_value: "Partager la valeur",
    created_at: "Date de création",
    established_on: "Date d'établissement",
  },

  notifications: {
    group_exist_error: "Nom du groupe déjà pris. Veuillez en choisir un autre",
    loan_error: "Error: Member has an active loan",
    edit_success: "Mise à jour réussie",
    edit_error: "Erreur de mise à jour",
    signup_success: "Inscrit avec succès",
    success: "Ajouté avec succès",
    error: "Échec",
    group_create_success: "Complété avec succès",
    group_create_error: "Erreur de traitement des données",
    activation_success: "Groupe activé avec succès",
    activation_error: "Échec: activer groupe",
    meeting_end_success: "Réunion terminée",
    meeting_end_error: "Echec: terminer la réunion",
    meeting_create_success: "La réunion a commencé",
    meeting_create_error: "Echec: commencer la réunion",
    member_create_success: "Membre ajouté avec succès",
    member_create_error: "Echec: ajouter membre",
    no_previous_meeting: "Il n'y a pas de réunions précédentes pour ce groupe",
    no_transactions: "Il n'y a aucune transaction pour cette réunion",
    meeting_no_member_warning:
      "Vous ne pouvez pas commencer une réunion avant d'avoir ajouter les membres du groupement",
    loan_amount_error: "Le montant du prêt dépasse le montant autorisé",
    share_amount_error: "Shares amount exceeds allowed amount",
    share_value_loading: "chargement de la valeur de partage",
    cant_update_shares:
      "La réunion en cours ne peut pas mettre à jour la valeur de partage",
    member_phone_error: "Number déjà utilisé",
    login_phone_error: "Ce numéro de téléphone existe déjà dans le système",
    savings_fund_mandatory:
      "Désélectionnez les fonds inutilisés. Un fonds d'Épargne est requis",
    fund_create_success: "Fonds créé avec succès",
    gdpr_warning:
      "En procédant à la création de votre, vous attestez avoir 18 ans ou plus",
    can_not_request_welfare:
      "Le solde du bien-être est nul, la demande n'est pas possible",
    no_reversible_transactions: "Aucune transaction réversible trouvée",
    activate_reversible_transactions:
      "Cochez la case pour activer l'annulation des transactions",
    welfare_amount_warning: "Le montant dépasse le montant autorisé",
    pin_success: "Le code PIN a été modifié avec succès",
    pin_error: "Entrez le bon code! Réessayer",
    cancel_txn: "Transaction cancelled",
    processing: "Processing Transaction",
    cant_delete_member_has_funds:
      "Le membre ne peut pas être supprimé si le portefeuille est financé ou si la réunion est en cours",
    cant_delete_group_has_funds:
      "Le groupe ne peut pas être supprimé si le portefeuille est financé ou si la réunion est en cours",
    end_of_cycle_cash_out_warning:
      "Once you've started end of cycle cashout, you won't be able to add any new transaction records until cash out is completed.",
    cash_out_success_msg: "Cash out completed",
    share_value_information:
      "You are starting a new saving cycle. Do you want to change the share value?",
    share_value_updated_msg: "Share value updated!",
    reversal: "Inversion de Traitement",
    mid_cycle_cash_out_warning:
      "Une fois que vous avez commencé le retrait à mi-cycle, vous ne pourrez pas ajouter de nouveaux enregistrements de transaction tant que le retrait n'est pas terminé.",
    proceed_to_cashout_msg: "Écrivez «retrait» pour continuer",
    cashout: "retrait",
    incorrect_username_password_msg: "Nom d'utilisateur ou code PIN incorrect",
    contactHiveForSupportMsg:
      "Erreur lors de l'envoi du code. Contactez hiveonline pour obtenir de l'aide",
  },

  badge: {
    meeting_on: "Fin de la réunion",
  },

  span: {
    current_cycle: "Current Cycle",
    previous_cycles: "Previous cycles",
    transaction_status: "Transactions status",
    login_span: "Vous n'avez pas de compte ?",
    signup: "S'inscrire",
    signup_span: "Avoir un compte ?",
    login: "S`identifier",
    welfare: "sociale",
    savings: "actions",
    fine: "amende",
    endOfCycle: "Fin de cycle",
    midCycle: "Milieu de cycle",
    membership: "Adhésion",
    sharedBased: "Shares Based",
    equal: "Equal",
    refund: "Refund",
    selectCashOutType: "Select Group Cash Out Type",
    savingsFund: "Savings Fund",
    membershipFund: "Membership Fund",
    welfareFund: "Welfare Fund",
    fineFund: "Fine Fund",
    equally: "Equally",
    fineCashOutType: "Fine Cash Out Type",
    welfareCashOutType: "Welfare Cash Out Type",
    CashOutTypeSelect: "Select Cash Out Type",
    selectFund: "Select Fund",
    loanServiceCharge: "Loan Service Charge",
    member_role: "Member Role",
    processing: "processing",
    no_records_found: "No transactions records found",
    loan_purpose: "Loan purpose",
    cancel: "Cancel",
    save: "Save",
    saving: "Saving...",
    bc_wallet: "Portefeuille BC",
    link: "Lien",
  },
  banner: {
    install: "Installer",
  },
  locale: {
    date: "fr-FR",
  },
  memberActions: {
    confirm_message: `Supprimer membre?`,
  },
  cashOut: {
    settings_summary_title: "These are the cash-out types set for each fund:",
    savings: "Savings Fund",
    membership: "Membership Fund",
    Welfare: "Welfare Fund",
    fine: "Fine Fund",
    loan_interest: "Loan interest fund",
    call_to_action: "You can change these settings",
    write_here: "Écrivez ici",
  },
  settings: {
    disclaimer:
      "you can only change cash-out types settings if no transaction has been added to current meeting",
  },
  status: {
    SUCCESSFUL: "SUCCESSFUL",
    PENDING: "PENDING",
    FAILED: "FAILED",
  },
  locations: {
    country: "Pays",
    county: "Comté",
    province: "Province",
    region: "Région",
    district: "District",
    gh_district: "Métropolitaine/Municipale/District",
    municipality: "Municipalité",
    sub_county: "Sous-comté",
    administrative_post: "Poste administratif",
    locality: "Localité",
    select_country: "choisissez le pays",
    select_county: "sélectionner le comté",
    select_sub_county: "sélectionner un sous-comté",
    select_province: "sélectionner une province",
    select_region: "choisissez une région",
    select_district: "sélectionner un quartier",
    select_municipality: "sélectionner la commune",
    select_administrative_post: "sélectionner un poste administratif",
    select_locality: "sélectionner l'adresse",
    neigbourhood_name: "Quartier/Nom de la rue",
  },
}
