export default {
  learningContent: {
    buttonTitle: "Educacional",
    title: "Aprendizagem",
    description:
      "Aqui pode encontrar materiais didáticos que podem ajudar os agricultores a desenvolver as suas competências e a aumentar os seus conhecimentos agrícolas.",
    button: "Ir para Aprendizagem",
    courses: "Cursos",
    content: "Conteúdo",
    downloads: "Transferências",
    list: "Lista",
    filter_list_text: "Filtrar lista por",
    lessons: "Lista de Lições",
    lessonView: "Lições",
    language: "Língua",
    course: "Curso",
    select_language: "Selecionar o Idioma",
    search_course: "Pesquisar curso...",
    download_msg: "A suas aulas transferidas aparecerão aqui",
    selected_filters: "Filtros seleccionados",
    views: "Visualizações",
    download: "Transferir",
    downloading: "Transferindo...",
    delete: "Apagar",
    deleting: "Apagando...",
    NoSpaceAvailable: "Sem espaço de armazenamento suficiente",
    lesson: "Instrução",
  },
  error_msg: {
    transactions_error:
      "O registo de TRANSACTION_NAME de AMOUNT MIL CURRENCY para MEMBER_NAME falhou. O que pretende fazer com a transação?",
  },
  countries: {
    Niger: "Niger",
    Tanzania: "Tanzânia",
    Rwanda: "Ruanda",
    Zambia: "Zâmbia",
    Honduras: "Honduras",
    Nigeria: "Nigéria",
    Lebanon: "Líbano",
    Mozambique: "Moçambique",
    Moçambique: "Moçambique",
    Ghana: "Gana",
    Syria: "Síria",
    Palestine: "Palestina",
  },
  title_copy: {
    status: "status",
    version_msg: "Você está usando a versão de teste do vsla.online",
    cycles_title: "ciclos de poupança",
    header_login: "INICIAR SESSÃO",
    header_signup: "REGISTRAR",
    create_group: "CRIAR GRUPO",
    group_finance: "FUNDO DE ASSISTÊNCIA SOCIAL:",
    group_header: "GRUPO",
    loading: "CARREGANDO...",
    updating: "ACTUALIZANDO E ACTIVANDO ....",
    check_status: "verificando o status do grupo",
    no_vsla: "Nenhum grupo encontrado",
    create_meeting: "CRIAR REUNIÃO",
    meeting_members: "Membros",
    meeting: "REUNIÃO",
    prev_meeting: "REUNIÕES ANTERIORES",
    offline_meeting: "Reunião offline",
    add_member: "INFORMAÇÕES PESSOAIS",
    buy_shares: "COMPRAR AÇÕES",
    sell_shares: "VENDER AÇÕES",
    fine: "PAGAR MULTA",
    welfare: "CONTRIBUIÇÃO SOCIAL",
    welfare_request: "PEDIDO SOCIAL",
    membership_fee: "TAXA DE MEMBROS",
    loan: "EMPRÉSTIMO",
    funds: "FUNDOS",
    more_details: "Mais detalhes",
    vsla_actions: "Actividades de grupo",
    mem_actions: "Actividades de Membro",
    memberlist_overview: "LISTA",
    mem_menu: "ADICIONAR MEMBROS",
    loan_repayment: "PAGAMENTO DE EMPRÉSTIMO",
    loan_writeoff: "ELIMINAR EMPRÉSTIMO",
    outstanding_loan: "Empréstimo Não Pago:",
    outstanding_loan_interest: "Juros de empréstimo não pagos:",
    outstanding_service_charge: "Juros de empréstimo não pagos:",
    attendees: "Participantes da reunião",
    share_value: "Valor da Ação",
    cashOutType: "Tipo de saque",
    settings: "DEFINIÇÕES",
    current_savings: "Poupança",
    cycle_duration: "Duração do Ciclo",
    addFundAccount: "ADICIONAR CONTA DE FUNDO",
    supported_accounts: "Contas com suporte",
    lastTransaction: "Última transação",
    otherIncome: "Renda do grupo",
    otherExpenses: "Despesas do grupo",
    cashOut: "Levantamento de dinheiro",
    warning: "Aviso",
    end_of_cycle_cash_out: "Retirada de dinheiro em fim de ciclo",
    reminder: "Lembrete",
  },

  menu: {
    learning_content: "tutorials",
    vlsa: "GRUPO DE POUPANÇA (VSLA)",
    group: "Grupo",
    meeting: "Reunião",
    create_vsla: "Criar Grupo",
    vsla_overview: "Visão geral do grupo",
    new_meeting: "Nova Reunião",
    prev_meeting: "Reuniões Anteriores",
    offline_meeting: "Reunião offline",
    vsla_saving: "Fundo de Poupança:",
    vlsa_loan: "Empréstimos totais:",
    vlsa_balance: "Saldo Actual:",
    funds: "Fundos",
    members: "Membros",
    members_overview: "Visão geral dos membros",
    add_member: "Adicionar membro",
    end_meeting: "Terminar Reunião",
    current_meeting: "Reunião Atual",
    settings: "Definições",
    options: "Opções",
    language: "Idioma",
    close: "Fechar",
    savingsFund: "Fundo de poupança",
    welfareFund: "Fundo Social",
    loanInterestFund: "Fundo de juros de empréstimo",
    fineFund: "Fundo de Multas",
    membershipFund: "Fundo de Membresia",
    end_of_cycle_cash_out: "Retirada de dinheiro em fim de ciclo",
  },

  menu_mem: {
    overview: "Visão geral dos membros",
    welfare: "Contribuição Social",
    welfare_request: "Pedido Social",
    buy_shares: "Comprar ações",
    create_loan: "Criar Empréstimo",
    repay_loan: "Pagar Empréstimo",
    writeoff_loan: "Eliminar Empréstimo",
    pay_fine: "Pagar Multa",
    membership_fee: "Taxa de Membros",
    sell_shares: "Vender Ações",
    transaction_amount: "Valor da transação",
    other_income: "Outros rendimentos",
    other_expenses: "Outras despesas",
    cashout: "Sacar",
    group_income: "Rendimento de Grupo",
    group_expense: "Desepesas de Grupo",
    fine_payment: "Pagamento de multa",
    welfare_contribution: "Contribuição social",
    loan_repayment: "Reembolso do empréstimo",
    loan_interest_repayment: "Reembolso dos juros do empréstimo",
    fine_payment_reversal: "Anulação de pagamento de multa",
    buy_shares_reversal: "Compra de acções invertida",
    welfare_contribution_reversal: "Contribuição Social Invertida",
    sell_shares_reversal: "Venda de acções revertida",
    loan_interest_repayment_reversal: "Reembolso de juros de empréstimo invertido",
    welfare_request_reversal: "Pedido de contribuição social invertido",
    membership_fee_reversal: "Quotas de membresia invertida",
    savings_cash_out: "Retirada de poupança",
    midCycle_Cashout: "Retirada de dinheiro a meio do ciclo",
    group_income_fund: "Renda do grupo - Poupança",
    group_income_fine: "Renda do grupo - Multa",
    group_income_welfare: "Renda do grupo - Bem-estar",
    group_expense_fund: "Despesa do grupo - Poupança",
    group_expense_fine: "Despesa do grupo - Multa",
    group_expense_welfare: "Despesa do grupo - Bem-estar",
  },

  mem_overview: {
    welfare: "Contribuição Social:",
    shares_in_francs: "Ações em valor:",
    loan: "Montante do empréstimo:",
    shares_in_numbers: "Ações em números:",
    welfare_total: "Social:",
    shares_total: "Ações de Membros:",
  },

  header: {
    home: "Início",
    vsla: "Grupo",
    logout: "Terminar Sessão",
    whats_new: "Novidades",
    tutorials: "Tutoriais",
    legal: "Legítimo",
  },

  placeholder_copy: {
    search_no_result_message: "Não encontrado",
    search_placeholder: "Nome de pesquisa",
    loader_msg1: "Criar perfil de grupo",
    loader_msg2: "Atribuindo uma secretária de aplicativo",
    loader_msg3: "Criar entradas de contas",
    loader_msg4: "Alocando contas para grupo",
    add_account_loader_msg: "Criando contas, etapa final",
    group_loader_msg: "Criando grupo, só um momento",
    secretary_pay_interest: "O secretário paga juros sobre empréstimos",
    gender: "Género",
    female: "Mulher",
    male: "Hómem",
    sms_code: "Código de verificação",
    submit: "Enviar",
    income_amount: ".valor..",
    login_email: "Número de telefone",
    login_password: "PIN...",
    login_submit: "Iniciar Sessão",
    login_submitting: "Iniciando Sessão...",
    signUp_submit: "Registrar",
    signUp_submitting: "Registrando..",
    create_group_name: "Nome do grupo...",
    create_group_location: "Localização do grupo...",
    create_group_submit: "Criar grupo",
    create_fund_submit: "Criar Fundo",
    meeting_date: "Selecione a data",
    meeting_submit: "Criar Reunião",
    meeting_submitting: "Criando Reunião",
    member_submit: "Criar Membro",
    member_name: "Nome completo",
    member_phone: "Número de telefone",
    number_of_shares: "Número de ações...",
    shares_submit: "Comprar ações",
    share: "Ação",
    loan_amount: "Montante do empréstimo...",
    loan_submit: "Criar Empréstimo",
    welfare_amount: "Valor do bem-estar...",
    welfare_submit: "Pagamento Social",
    payment_amount: "Valor de Pagamento...",
    repay_submit: "Pagar Empréstimo",
    loan_writeoff: "Eliminar Empréstimo",
    share_value_amount: "Montante do valor da ação..",
    share_value_submit: "Atualizar configurações",
    create_group_share_value: "Valor do ação do ciclo do grupo",
    role_in_group: "Função no grupo",
    login_pin: "PIN",
    login_pin_confirm: "Confirmar PIN",
    create_group_federation: "Federação...",
    savings_cycle: "Selecione o ciclo de poupanças..",
    pays_interest: "O membro paga juros sobre empréstimos?",
    country: "Selecione o país",
    date_of_birth: "Data de nascimento",
    add_secretary_header: "Adicionando Secretário",
    add_secretary_msg: "A secretária será adicionada primeiro.",
    add_secretary_status: "Adicionando..",
    add_secretary: "Adicionar Secretário",
    total_amount: "Montante total",
    fine_amount: "Montante de Multa....",
    fine_submit: "Pagar multa",
    membership_amount: "Taxa de membro...",
    membership_submit: "Pagar taxa de membro",
    sell_shares: "Vender Ações",
    membership_reverse: "Reverter",
    service_charge_amount: "Valor da taxa de serviço",
    forgot_pin: "Esqueceu o seu PIN?",
    account_associated: "Insira o número de telefone associado à sua conta",
    token_sent: "Digite o código de 4 dígitos que você recebeu por SMS.",
    resend_token: "Reenviar o código",
    submit_smscode: "Digite seu novo código PIN de 4 dígitos",
    income_submit: "Sacar",
    select_nationality: "Selecionar nacionalidades",
    next: "Seguinte",
    cashingOut: "Levantamento de dinheiro...",
    finish: "Terminar",
    currency: "Selecionar a moeda",
    select: "Seleccionar",
    savings_amount: "Montante da poupança",
    omt_recipt_number: "Número de recibo OMT",
    enter_recipt_number: "Introduzir o número de recibo",
    savings_withdrawal: "Levantamento de poupança",
    project_name: "Nome do Projeto",
    retry: "Tentar novamente",
    remove: "Remova",
    secretary_creation_msg: "A criação do secretário está em andamento...",
    secretary_creation_failed_msg:
      "A criação da secretária falhou. Por favor tente novamente",
  },

  delete: "Apagar Grupo",
  delete_member: "Apagar Membro",
  edit: "Editar Grupo",
  save: "Guardar",
  edit_member: "Editar Membro",

  alert: {
    upload_error:
      "O carregamento da transação offline falhou. Ir para o registo da reunião offline para ver o erro ",
    upload_success:
      "As transacções offline foram carregadas e resolvidas. Consulte os registos de reuniões anteriores para ver isso",
    no_members: "Não há membros neste grupo",
    no_savings_cash_out_personal:
      "Caixa de poupança bloqueada, empréstimo pessoal não liberado",
    no_savings_cash_out_group:
      "Caixa de poupança bloqueada, empréstimo não liberado em grupo",
    no_membership_cash_out_personal:
      "Retirada de dinheiro de sócios bloqueada, empréstimo pessoal não liberado",
    no_membership_cash_out_group:
      "Retirada de dinheiro de sócios bloqueada, empréstimo não liberado em grupo",
    no_fine_cash_out_personal:
      "Dinheiro fino bloqueado, empréstimo pessoal não liberado",
    no_fine_cash_out_group:
      "Dinheiro fino bloqueado, empréstimo não liberado no grupo",
    no_welfare_cash_out_personal:
      "Saída de dinheiro da previdência bloqueada, empréstimo pessoal não liberado",
    no_welfare_cash_out_group:
      "Saída de dinheiro da previdência bloqueada, empréstimo não liberado no grupo",
    cash_out_success: "Todas as condições foram atendidas para permitir o saque",
    success: "Sucesso",
    error: "Erro",
    cash_out_type_not_define:
      "O tipo de saque para este fundo não foi definido, atualize-o no menu de configurações",
    cannot_end_meeting: "Can't end the meeting while a transaction in progress",
    failed: "Falhado",
    repay_loans_message: "Reembolsar os empréstimos feitos pelos membros",
    cannot_cash_out:
      "ão é possível levantar dinheiro durante uma transação em curso",
    all_members_cashed_out: "Todos os membros foram retirados",
    no_internet: "Sem Internet",
    uploading_transactions: "Carregamento de transacções...",
    member_cashed_out: "O membro foi sacado",
    member_pending_loan: "O membro tem um empréstimo que precisa de reembolsar",
    cannot_delete_member_cashout_msg:
      "Não é possível excluir o membro enquanto o saque está em andamento",
    cannot_delete_member_secretary_msg:
      "Não é possível excluir a secretária do grupo",
    cannot_delete_member_has_balance_msg:
      "Não é possível excluir, o membro precisa ser sacado primeiro",
  },

  dropdowns: {
    secretary: "Secretário/a",
    chairperson: "Presidente",
    keyHolder: "Chaveiro/a",
    box_keeper: "Guardião da caixa",
    organiser: "Organizador",
    money_counter: "Contador/a de dinheiro",
    member: "Membro",
    key_holder: "Key Holder",
    yes: "Sim",
    no: "Não",
    nine_months: "9 meses",
    six_months: "6 meses",
    year: "1 ano",
    establishment_date: "",
    business_development: "Desenvolvimento de negócios",
    personal_emergency: "Emergência",
    other: "Other",
  },

  cards: {
    total_member: "Total de membros",
    cycle_duration: "Duração do Ciclo",
    country: "País",
    locality: "Localidade",
    federation: "Federação",
    share_value: "Valor de Ação",
    months: "meses",
    created_at: "Criado",
    established_on: "Estabelecimento em",
  },

  notifications: {
    group_exist_error: "O nome do grupo já está em uso. Escolha um diferente",
    edit_success: "Atualizado com sucesso",
    edit_error: "Erro ao atualizar",
    signup_success: "Conta criada com sucesso",
    success: "Completado com sucesso",
    error: "Não completou com sucesso",
    group_create_success: "Grupo criado com sucesso",
    group_create_error: "Erro ao criar grupo",
    activation_success: "Grupo ativado com sucesso",
    activation_error: "Erro ao ativar o grupo",
    meeting_end_success: "Reunião encerrada",
    meeting_end_error: "Erro ao terminar reunião",
    meeting_create_success: "Reunião iniciada",
    meeting_create_error: "Reunião não começou",
    member_create_success: "Membro criado com sucesso",
    member_create_error: "Erro ao criar membro",
    meeting_no_member_warning:
      "Não é possível começar a reunião antes de adicionar membros ao grupo",
    no_previous_meeting: "Não há reuniões anteriores para este grupo",
    no_transactions: "Não há transações para esta reunião",
    loan_amount_error: "O valor do empréstimo excede o valor permitido",
    share_amount_error: "O valor das ações excede o valor permitido",
    share_value_loading: "Obtendo o valor das ações atuais",
    cant_update_shares:
      "Reunião em andamento, não é possível atualizar o valor da ação",
    member_phone_error: "Número em uso",
    savings_fund_mandatory:
      "Desmarque fundos não utilizados. É necessário fundos de poupança",
    fund_create_success: "Fundo criado com sucesso",
    gdpr_warning:
      "Ao prosseguir com a criação da conta, você confirma que tem 18 anos de idade ou mais",
    can_not_request_welfare:
      "Saldo de fundo social atual é zero, não é possível solicitar",
    no_reversible_transactions: "Nenhuma transação reversível encontrada",
    activate_reversible_transactions:
      "Marque a caixa para ativar a reversão de transações",
    welfare_amount_warning: "O valor excede o valor permitido",
    pin_success: "PIN alterado com sucesso",
    pin_error: "Insira o código correto! Tente novamente",
    cancel_txn: "Transação cancelada",
    processing: "Processando Transação",
    pay_out_standing_charge_first:
      "A taxa de taxa de serviço deve ser libertada antes do reembolso do empréstimo",
    cant_delete_member_has_funds:
      "O membro não pode ser excluído se ele tiver dinheiro restante ou durante a reunião",
    cant_delete_group_has_funds:
      "O grupo não pode ser excluído se a carteira for financiada ou se a reunião estiver em andamento",
    end_of_cycle_cash_out_warning:
      "Uma vez iniciado o levantamento de fim de ciclo, não será possível adicionar novos registos de transacções até que o levantamento esteja concluído.",
    cash_out_success_msg: "Retirada de dinheiro concluída or Levantamento concluído",
    share_value_information:
      "Está a iniciar um novo ciclo de poupança. Deseja alterar o valor da ação?",
    share_value_updated_msg: "Valor da ação atualizado!",
    reversal: "Processando reversão",
    mid_cycle_cash_out_warning:
      "Depois de iniciar o saque no meio do ciclo, você não poderá adicionar novos registros de transação até que o saque seja concluído.",
    proceed_to_cashout_msg: "Escreva “saque” para prosseguir",
    cashout: "saque",
    incorrect_username_password_msg: "Nome de usuário ou PIN incorreto",
    contactHiveForSupportMsg:
      "Erro no envio do código. Entre em contato com a hiveonline para obter suporte",
  },

  badge: {
    meeting_on: "Terminar Reunião",
  },

  span: {
    current_cycle: "Ciclo Actual",
    previous_cycles: "Ciclos Anteriores",
    transaction_status: "Estado das transacções",
    selectFund: "Selecione o fundo",
    CashOutTypeSelect: "Selecione o tipo de saque",
    login_span: "Não tem uma conta?",
    signup: "Registrar",
    signup_span: "Já possui uma conta?",
    login: "Iniciar Sessão",
    welfare: "Fundo Social",
    savings: "Poupanças",
    fine: "Multa",
    endOfCycle: "Fim do ciclo",
    midCycle: "Meio do Ciclo",
    membership: "Filiação de Membro",
    sharedBased: "Base de Ações",
    equal: "Igual",
    refund: "Reembolso",
    selectCashOutType: "Selecione o tipo de saque de grupo",
    savingsFund: "Fundo de Poupanças",
    membershipFund: "Fundo de Membros",
    welfareFund: "Fundo Social",
    fineFund: "Fundo de Multas",
    loanInterestFund: "Fundo de juros de empréstimo",
    equally: "Igualmente",
    fineCashOutType: "Tipo de saque de multa",
    loanServiceCharge: "Taxa de serviço de empréstimo",
    welfareCashOutType: "Tipo de saque social",
    member_role: "Função do membro",
    processing: "em processamento",
    back: "Voltar",
    continue: "Continuar",
    no_records_found: "Nenhum registro de transação encontrado",
    loan_purpose: "Finalidade do empréstimo",
    cancel: "Cancelar",
    save: "Guardar",
    saving: "Guardando..",
    bc_wallet: "Carteira BC",
    link: "Link",
  },
  banner: {
    install: "Instalar",
  },
  locale: {
    date: "pt-PT",
  },
  memberActions: {
    confirm_message: `Apagar Membro?`,
  },
  cashOut: {
    settings_summary_title:
      "Estes são os tipos de levantamento definidos para cada fundo:",
    savings: "Fundo de poupança",
    membership: "Fundo de Participação",
    Welfare: "Assistência Social",
    fine: "Fundo de Multas",
    loan_interest: "Fundo de juros de empréstimos",
    call_to_action: "É possível alterar estas definições",
    write_here: "Escreva aqui",
  },
  settings: {
    disclaimer:
      "só é possível alterar as configurações dos tipos de retirada de dinheiro  se nenhuma transação tiver sido adicionada à reunião atual",
  },
  status: {
    SUCCESSFUL: "Transação feita com Sucesso",
    PENDING: "Transação Pendente",
    FAILED: "Transação falhou",
  },
  locations: {
    country: "País",
    county: "Condado",
    province: "Província",
    region: "Região",
    district: "Distrito",
    gh_district: "Metropolitano/Municipal/Distrito",
    municipality: "Município",
    sub_county: "Subcondado",
    administrative_post: "Posto Administrativo",
    locality: "Localidade",
    select_country: "selecione o pais",
    select_county: "selecione o condado",
    select_sub_county: "selecione o subcondado",
    select_province: "selecione a província",
    select_region: "selecione a região",
    select_district: "selecione distrito",
    select_municipality: "selecione município",
    select_administrative_post: "selecione posto administrativo",
    select_locality: "selecionar endereço",
    neigbourhood_name: "Nome de bairro/rua",
  },
}
